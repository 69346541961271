.container {
    width: 100%;
    border: 1px solid @colorBorder !important;
    border-radius:~"@{borderRadius}px";
    overflow: hidden;
    background-color: @component-background;

    :global {
        .ant-empty {
            padding: 16px 0;
            margin: 0;
        }

        .ant-table-container {
            border-bottom: 0 !important;
            border-radius: 0 !important;
            border: 0 !important;

            tbody {
                tr:last-child td {
                    border-bottom: 0 !important;
                }
            }

            td,
            th {
                border-radius: 0 !important;
            }
        }

        .ant-table-footer {
            border-top: 1px solid @border-color-split;
            border-radius: 0 !important;
        }

    }
}

.condensedFormField {
    margin-bottom: 0px !important;
}

.dropdownCreateButton {
    text-align: center;
    width: 100%;
}